.spinner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full-screen height for centering */
    background-color: #f8f9fa; /* Soft, light background color */
    font-family: 'Arial', sans-serif; /* Consistent font style */
}

.spinner {
    display: inline-block;
    width: 64px;
    height: 64px;
    border: 6px solid rgba(0, 0, 0, 0.1); /* Semi-transparent light border */
    border-radius: 50%;
    border-top-color: #3498db; /* Blue border color for the spinner */
    animation: spin 1s ease-in-out infinite;
    position: relative;
    margin-bottom: 20px; /* Space between spinner and loading text */
}

.spinner::before {
    content: "";
    position: absolute;
    top: 6px;
    left: 6px;
    right: 6px;
    bottom: 6px;
    border: 6px solid rgba(0, 0, 0, 0.1); /* Inner semi-transparent border */
    border-radius: 50%;
    border-bottom-color: #e74c3c; /* Red border color for inner spinner */
    /* Removed spinReverse animation */
}

.loading-text {
    display: flex;
    align-items: center;
    font-size: 18px; /* Text size for loading message */
    color: #333; /* Darker color for readability */
}

.loading-icon {
    width: 24px; /* Size of the icon */
    height: 24px; /* Size of the icon */
    margin-right: 10px; /* Space between icon and text */
    display: flex;
    align-items: center;
    justify-content: center;
    animation: pulse 1.5s infinite; /* Simple pulsing animation for the icon */
    font-size: 24px; /* Adjust icon size if necessary */
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@keyframes pulse {
    0%, 100% { opacity: 0.6; }
    50% { opacity: 1; }
}

@font-face {
  font-family: 'Oswald';
  src: url('./assets/fonts/Oswald-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'RobotoCondensed-Regular';
  src: url('./assets/fonts/RobotoCondensed-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'UTM Bebas';
  src: url('./assets/fonts/UTM-Bebas.ttf') format('truetype');
  font-weight: 700;
}

body {
  font-family: 'UTM Bebas', sans-serif;
}

.nav-link_language {
    font-weight: bold;
    font-family: "bebas neue", cursive;
    padding: 0 2px;
    position: relative;
    padding-bottom: 5px;
    transition: color 0.3s ease; /* Smooth transition for text color change */
}
.nav-link_language:focus, .nav-link_language:hover {
    color: #fff;
}
.nav-link_language::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: yellow; /* This can be changed to border-bottom: 2px solid yellow; if necessary */
    transition: width 0.3s ease;
}
header .nav-link_language:hover::after, .nav-link_language.active::after {
    width: 100%;
}
.slider-slide:hover {
    cursor: pointer;
}
/* Container styling */
.slide-container {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden; /* Hides overflow to keep the zoom effect within the bounds */
    display: inline-block;
}
.slide-image {
    width: 100%;
    height: 100%; /* Allows image to scale with container width while maintaining aspect ratio */
}
.animate-image {
    animation: zoomInOut 10s ease-in-out infinite; /* Applies the zoom animation */
}
.text-banner {
    position: absolute;
    top: 50%;
    left: 25%;
    width: 40%;
    transform: translate(-50%, -50%);
    padding: 1vw 2vw;
    color: white;
    font-size: 5em;
    text-align: left;
    text-transform: uppercase;
    font-family: 'UTM Bebas';
    line-height: 90px;
    animation: fadeIn 2s ease-in forwards;
}
.text-banner-right {
    position: absolute;
    top: 50%;
    left: 75%;
    width: 40%;
    transform: translate(-50%, -50%);
    padding: 1vw 2vw;
    color: white;
    font-size: 5em;
    text-align: right;
    text-transform: uppercase;
    font-family: 'UTM Bebas';
    line-height: 90px;
    animation: fadeIn 2s ease-in forwards;
}
.scroll-indicator {
    font-family: 'UTM Bebas';
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: 2%;
    color: white;
    font-size: 20px;
    cursor: pointer;
}
.product-button-color {
    background-color: #F00F0F !important;
}
.margin-top__80px {
    margin-top: 80px;
}
.backdrop-container {
    position: relative;
    display: inline-block;
    min-width: 100%;
}
.backdrop-image {
    height: 400px !important;
    width: 100% !important;
    object-fit: cover;
}
.text-backdrop {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.text-backdrop > h2 {
    color: white; /* Change to your desired text color */
    font-size: 3rem; /* Adjust font size as needed */
    text-align: center;
    font-weight: bold; /* Optional: makes the text bold */
    text-transform: uppercase;
}
.backdrop-arrow {
    display: flex;
    justify-content: center;
}
.backdrop-down-icon {
    color: #fff;
    cursor: pointer;
}
.product-detail__item {
    margin-top: 100px;
}

.product-item__image:hover img {
    -webkit-transform: scale(1.2, 1.2);
    transform: scale(1.2, 1.2);
}
.product-page-list {
    width: var(--theme-var-content) !important;
}
.hidden-action-paginate {
    display: none !important;
}
.active-action-paginate > a {
    color: var(--theme-color-inverse_link) !important;
    background-color: var(--theme-color-text_link) !important;
    border-color: var(--theme-color-text_link) !important;
}
.search-container {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%; /* Adjust the width as needed */
}

.search-icon {
    position: absolute;
    left: 15px; /* Adjust the position as needed */
    color: #8b857d; /* Adjust the icon color */
    pointer-events: none; /* Make the icon unclickable */
}

.search-input {
    width: 100%; /* Adjust the width as needed */
    padding-left: 35px; /* Space for the icon */
    padding-right: 10px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: 4px;
    border: 1px solid #ccc; /* Adjust the border as needed */
}

.search-input:focus {
    outline: none;
    border-color: #007bff; /* Optional: Add focus border color */
}

.sc_main_title_text {
    font-family: 'UTM Bebas';
    font-size: 3rem;
}
.wpcf7-submit-style {
    background-color: var(--theme-color-text_link);
}
.sc_googlemap {
    height: 590px;
}
.category-active {
    color: #b89578 !important;
}
.image-container {
    position: relative;
    display: inline-block; /* Ensures the container only takes up as much space as its contents */
}
.centered-frame {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px; /* Set the fixed width */
    height: 200px; /* Set the fixed height */
    background-color: rgb(65, 4, 4, 0.7); /* Optional: Adds a semi-transparent background */
    color: white; /* Optional: Sets text color */
    display: flex; /* Use flexbox to center text */
    align-items: center; /* Vertically centers the text */
    justify-content: center; /* Horizontally centers the text */
    text-align: center; /* Centers the text inside the frame */
}
.centered-text {
    font-family: 'UTM Bebas';
    font-size: 2rem;
    line-height: 1.2em;
    padding: 20px;
}
@keyframes zoomInOut {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.1);
    }
}

@keyframes fadeIn {
    from {
      opacity: 0; /* Start with invisible */
    }
    to {
      opacity: 1; /* End with fully visible */
    }
}
.arrow-down-icon {
    margin-left: 5px;
    animation: bounce 4s infinite;
}
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        transform: translateY(0);
    }
    40% {
        transform: translateY(-10px);
    }
    60% {
        transform: translateY(-5px);
    }
}

.temp-header-top {
    height: 87px;
    width: 100%;
    background-color: #220f04
}
.language-trans-mobile {
    display: block;
}
.socials_mobile {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.header-animation {
    animation: fadeInFromTop 0.8s ease-in-out forwards;
}
.news-wrapper {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
}
.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
}
.alert-error {
    color: #fff;
    background-color: #ed727e;
}

.alert {
    position: relative;
    padding: .75rem 1.25rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem;
}
.padding-up-down_10px {
    padding: 10px 0;
}
.font-family_utm_bebas {
    font-family: 'UTM Bebas'
}
@keyframes fadeInFromTop {
    0% {
      opacity: 0;
      transform: translateY(-20px); /* Start slightly above */
    }
    100% {
      opacity: 1;
      transform: translateY(0); /* End at the original position */
    }
}
/* For tablets */
@media only screen and (min-width: 768px) and (max-width: 1024px) {
    .temp-header-top {
        display: none;
    }
    .slide-image {
        height: 600px !important;
    }
    .text-banner {
        font-size: 4em;
        padding: 2vw 2vw;
        line-height: 72px;
        width: 60%;
        left: 35%;
    }
    .text-banner-right {
        font-size: 4em;
        line-height: 72px;
        left: 65%;
        width: 60%;
    }
    .scroll-indicator {
        font-size: 23px;
    }
    .language-trans-mobile {
        display: none;
    }
    .backdrop-image {
        height: 300px !important;
        width: 100% !important;
        object-fit: cover;
    }
    .sidebar_right [class*="content_wrap"] > .content {
        float: none !important;
    }
    .product-page-list {
        width: 100% !important;
    }
    .sc_googlemap {
        max-height: 50vh;
    }
}

/* For mobile devices */
@media only screen and (max-width: 767px) {
    .temp-header-top {
        display: none;
    }
    .slide-image {
        height: 320px !important;
    }
    .text-banner {
        font-size: 3em;
        padding: 3vw 6vw;
        line-height: 46px;
        width: 60%;
        left: 35%;
    }
    .text-banner-right {
        font-size: 3em;
        line-height: 46px;
        left: 65%;
        width: 60%;
    }
    .scroll-indicator {
        font-size: 16px;
    }
    .temp-header-top {
        display: none;
    }
    .backdrop-image {
        height: 220px !important;
        width: 100% !important;
        object-fit: cover;
    }
    .product-detail__item {
        margin-top: 50px;
    }
    .sidebar_right [class*="content_wrap"] > .content {
        float: none !important;
    }
    .product-page-list {
        width: 100% !important;
    }
    .sc_googlemap {
        max-height: 40vh;
    }
    .sc_main_title_text {
        font-size: 2rem;
    }
    .text-backdrop > h2 {
        font-size: 2rem;
    }
}
